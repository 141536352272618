import AEM from 'base/js/aem';
import { Modal } from 'bootstrap';

class CustomModal extends AEM.Component {
    init() {
        this.initModal();
    }
    initModal() {
        const modalElem = this.element.querySelector('.modal');
        const modalId = modalElem.getAttribute('id');
        const modalContainer = new Modal(modalElem);

        if (modalId){
            const params = new URLSearchParams(window.location.search);
            const modalIdParam = params.has('modal') ? params.get('modal') : '';

            if (modalIdParam !== '' && modalIdParam === modalId) {
                window.setTimeout(() => modalContainer.show(), 100);
            }
        }
        modalElem.addEventListener('hide.bs.modal', () => {
            if (window.location.hash.includes(modalId)) {
                window.history.pushState('', document.title, window.location.pathname + window.location.search);
            }
        });

        window.addEventListener('hashchange', () => {
            if (window.location.hash.substring(1) === modalId) {
                window.setTimeout(() => modalContainer.show(), 100);
            }
        });
    }
}

export { CustomModal };
